import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../Context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ObjectiveContainer from "../Components/ObjectiveContainer";
import "./ObjectiveEvaluation.css";
import ObjectiveFullfillmentContainer from "../Components/ObjectiveFullfillmentContainer";
import ObjectiveFullfillmentValidation from "../Components/ObjectiveFullfillmentValidation";
import ObjectiveFullfilmentValidationSecond from "../Components/ObjectiveFullfillmentValidationSecond";
import ObjectiveFullfillmentValidationContainer from "../Components/ObjectiveFullfillmentValidationContainer";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import Alert from "react-bootstrap/Alert";

function ObjectiveEvaluation() {
  const [status, setStatus] = useState("createObjectives");

  const [period, setPeriod] = useState({});

  const [declineComment, setDeclineComment] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [evaluation, setEvaluation] = useState({});

  const [objectives, setObjectives] = useState([]);

  const [files, setFiles] = useState([]);

  const [bossObjectives, setBossObjectives] = useState([]);

  const [show, setShow] = useState(false);

  const [showRejection, setShowRejection] = useState(false);

  const [showApproval, setShowApproval] = useState(false);

  const [showSecondApproval, setShowSecondApproval] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  let { eid } = useParams();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClose = () => setShow(false);

  const handleCloseReview = () => setShowRejection(false);

  const handleCloseApproval = () => setShowApproval(false);

  const handleCloseSecondApproval = () => setShowSecondApproval(false);

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/objectivesEvaluations/${eid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      if (response.data.evaluation.objectives.length === 0) {
        setObjectives([
          {
            _id: uuidv4(),
            description: "",
            weight: 0,
            bossObjective: null,
            userScore: 0,
            approverScore: 0,
            userComment: "",
            approverComment: "",
            secondApproverComment: "",
            secondApproverScore: 0,
          },
        ]);
      } else {
        setObjectives(response.data.evaluation.objectives);
      }
      setStatus(response.data.evaluation.status);
      setDeclineComment(response.data.evaluation.declineComment);
      setPeriod(response.data.period);
      setBossObjectives(response.data.bossObjectives);
      setEvaluation(response.data.evaluation);
    }
    fetch();
    setIsLoading(false);
  }, []);

  const handleAddObjective = () => {
    setObjectives([
      ...objectives,
      {
        _id: uuidv4(),
        description: "",
        weight: 0,
        bossObjective: null,
        userScore: 0,
        approverScore: 0,
        userComment: "",
        approverComment: "",
        secondApproverComment: "",
        secondApproverScore: 0,
      },
    ]);
  };

  const handleRemoveObjective = (id) => {
    setObjectives(objectives.filter((item) => item._id !== id));
  };

  const handleEvidenceChange = (e) => {
    setFiles([...files, { name: e.target.name, file: e.target.files[0] }]);
  };

  const handleChangeInput = (e, id) => {
    const newObjectives = objectives.map((item) => {
      if (id === item._id) {
        if (e.target.name === "evidencePath") {
          item[e.target.name] = e.target.files[0];
        } else {
          item[e.target.name] = e.target.value;
        }
      }
      return item;
    });

    setObjectives(newObjectives);
  };

  const handleDeclineComment = (e) => {
    setDeclineComment(e.target.value);
  };

  const checkWeights = () => {
    let sum = 0;
    for (const item of objectives) {
      sum += parseInt(item.weight);
    }
    if (sum !== 100) {
      setShow(true);
    } else {
      handleForward();
    }
  };

  const handleSaveForLater = async (e) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/objectivesEvaluations/save/${eid}`,
        data: {
          objectives: objectives,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/dashboard");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleDownloadEvidence = async (key) => {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/objectivesEvaluations/evidence/download`,
      data: { key: key },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    const link = document.createElement("a");
    link.download = key;

    link.href = response.data.url;

    link.target = "_blank";

    link.click();
  };

  const handleForward = async () => {
    setIsDisabled(true);
    const formData = new FormData();
    /* formData.append("file", file); */

    if (files.length > 0) {
      for (const item of files) {
        formData.append(item.name, item.file);
      }
    }

    formData.set("objectives", JSON.stringify(objectives));
    formData.set("declineComment", declineComment);

    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/objectivesEvaluations/forward/${eid}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/dashboard");
      setIsDisabled(false);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleRejectObjectives = () => {
    setShowRejection(true);
  };

  const handleRejectScores = () => {
    setShowApproval(true);
  };

  const handleRejectSecondScores = () => {
    setShowSecondApproval(true);
  };

  const handleBackward = async (e) => {
    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/objectivesEvaluations/backwards/${eid}`,
        data: {
          objectives: objectives,
          declineComment: declineComment,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/dashboard");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    if (status === "createObjectives") {
      return (
        <Container fluid>
          {declineComment === "" ? (
            <></>
          ) : (
            <Row>
              <Col>
                <Alert variant="danger" className="mb-3">
                  {declineComment}
                </Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="objective-evaluation">
              <Form>
                {objectives.map((element) => (
                  <ObjectiveContainer
                    id={element._id}
                    key={element._id}
                    description={element.description}
                    weight={element.weight}
                    bossObjectives={bossObjectives}
                    handleChangeInput={handleChangeInput}
                    handleRemoveObjective={handleRemoveObjective}
                    language={state.language}
                  />
                ))}
                <Form.Group className="mb-3">
                  <Button variant="primary" onClick={handleSaveForLater}>
                    {t("Client.ObjectivesEvaluation.GuardarParaDespues")}
                  </Button>
                  <Button variant="info" onClick={checkWeights}>
                    {t("Client.ObjectivesEvaluation.EnviarARevision")}
                  </Button>
                  <Button variant="dark" onClick={handleAddObjective}>
                    {t("Client.ObjectivesEvaluation.AgregarObjetivo")}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t("Client.ObjectivesEvaluation.RevisarPonderaciones")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Client.ObjectivesEvaluation.SumaDePonderaciones")}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                {t("Client.ObjectivesEvaluation.Entedido")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      );
    } else if (status === "inReview") {
      return (
        <Container fluid>
          <Row>
            <Col className="objective-evaluation">
              <Form>
                {objectives.map((element) => (
                  <ObjectiveContainer
                    id={element._id}
                    key={element._id}
                    description={element.description}
                    weight={element.weight}
                    bossObjective={element.bossObjective}
                    bossObjectives={bossObjectives}
                    handleChangeInput={handleChangeInput}
                    handleRemoveObjective={handleRemoveObjective}
                    language={state.language}
                  />
                ))}
                {state.userId === evaluation.approverId ||
                state.role === "root" ||
                state.role === "admin" ? (
                  <Form.Group className="mb-3">
                    <Button variant="primary" onClick={handleSaveForLater}>
                      {t("Client.ObjectivesEvaluation.GuardarParaDespues")}
                    </Button>
                    <Button
                      variant="info"
                      onClick={handleForward}
                      disabled={isDisabled}
                    >
                      {t("Client.ObjectivesEvaluation.ValidarObjetivos")}
                    </Button>
                    <Button variant="danger" onClick={handleRejectObjectives}>
                      {t("Client.ObjectivesEvaluation.RegresarObjetivos")}
                    </Button>
                    <Button variant="dark" onClick={handleAddObjective}>
                      {t("Client.ObjectivesEvaluation.AgregarObjetivo")}
                    </Button>
                  </Form.Group>
                ) : (
                  <></>
                )}
              </Form>
            </Col>
          </Row>
          <Modal show={showRejection} onHide={handleCloseReview}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t("Client.ObjectivesEvaluation.RegresarObjetivos")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="RejectionForm.TextArea">
                  <Form.Label>
                    {t("Client.ObjectivesEvaluation.PreguntaRegresoObjetivos")}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    onChange={handleDeclineComment}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseReview}>
                {t("Client.ObjectivesEvaluation.Cancelar")}
              </Button>
              <Button variant="danger" onClick={handleBackward}>
                {t("Client.ObjectivesEvaluation.RegresarObjetivos")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      );
    } else if (status === "inProgress") {
      return (
        <Container fluid>
          {declineComment === "" ? (
            <></>
          ) : (
            <Row>
              <Col>
                <Alert variant="danger" className="mb-3">
                  {declineComment}
                </Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="objective-evaluation">
              <Form>
                {objectives.map((element) => (
                  <ObjectiveFullfillmentContainer
                    key={element._id}
                    id={element._id}
                    description={element.description}
                    userComment={element.userComment}
                    handleChangeInput={handleChangeInput}
                    handleEvidenceChange={handleEvidenceChange}
                    userScore={element.userScore}
                    language={state.language}
                  />
                ))}
                <Form.Group className="mb-3">
                  <Button variant="primary" onClick={handleSaveForLater}>
                    {t("Client.ObjectivesEvaluation.GuardarParaDespues")}
                  </Button>
                  <Button
                    variant="info"
                    onClick={handleForward}
                    disabled={isDisabled}
                  >
                    {t("Client.ObjectivesEvaluation.EnviarARevision")}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      );
    } else if (status === "firstApproval") {
      return (
        <Container fluid>
          {declineComment === "" ? (
            <></>
          ) : (
            <Row>
              <Col>
                <Alert variant="danger" className="mb-3">
                  {declineComment}
                </Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="objective-evaluation">
              <Form>
                {objectives.map((element) => (
                  <ObjectiveFullfillmentValidation
                    key={element._id}
                    id={element._id}
                    description={element.description}
                    userComment={element.userComment}
                    evidencePath={element.evidencePath}
                    userScore={element.userScore}
                    approverId={evaluation.approverId}
                    handleChangeInput={handleChangeInput}
                    approverScore={element.approverScore}
                    approverComment={element.approverComment}
                    handleDownloadEvidence={handleDownloadEvidence}
                    language={state.language}
                  />
                ))}
                {state.userId === evaluation.approverId ||
                state.role === "root" ||
                state.role === "admin" ? (
                  <Form.Group className="mb-3">
                    <Button variant="primary" onClick={handleSaveForLater}>
                      {t("Client.ObjectivesEvaluation.GuardarParaDespues")}
                    </Button>
                    <Button
                      variant="info"
                      onClick={handleForward}
                      disabled={isDisabled}
                    >
                      {t("Client.ObjectivesEvaluation.ValidarCumplimientos")}
                    </Button>
                    <Button variant="danger" onClick={handleRejectScores}>
                      {t("Client.ObjectivesEvaluation.RegresarCumplimientos")}
                    </Button>
                  </Form.Group>
                ) : (
                  <></>
                )}
              </Form>
            </Col>
          </Row>
          <Modal show={showApproval} onHide={handleCloseApproval}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t("Client.ObjectivesEvaluation.RegresarCumplimientos")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="RejectionForm.TextArea">
                  <Form.Label>
                    {t(
                      "Client.ObjectivesEvaluation.PreguntaRegresoCumplimientos"
                    )}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    onChange={handleDeclineComment}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseApproval}>
                {t("Client.ObjectivesEvaluation.Cancelar")}
              </Button>
              <Button variant="danger" onClick={handleBackward}>
                {t("Client.ObjectivesEvaluation.RegresarCumplimientos")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      );
    } else if (status === "secondApproval") {
      return (
        <Container fluid>
          <Row>
            <Col className="objective-evaluation">
              <Form>
                {objectives.map((element) => (
                  <ObjectiveFullfilmentValidationSecond
                    key={element._id}
                    id={element._id}
                    description={element.description}
                    userComment={element.userComment}
                    evidencePath={element.evidencePath}
                    userScore={element.userScore}
                    approverId={evaluation.approverId}
                    handleChangeInput={handleChangeInput}
                    approverScore={element.approverScore}
                    approverComment={element.approverComment}
                    secondApproverComment={element.secondApproverComment}
                    secondApproverScore={element.secondApproverScore}
                    handleDownloadEvidence={handleDownloadEvidence}
                    language={state.language}
                  />
                ))}
                {state.userId === evaluation.secondApproverId ||
                state.role === "root" ||
                state.role === "admin" ? (
                  <Form.Group className="mb-3">
                    <Button variant="primary" onClick={handleSaveForLater}>
                      {t("Client.ObjectivesEvaluation.GuardarParaDespues")}
                    </Button>
                    <Button
                      variant="info"
                      onClick={handleForward}
                      disabled={isDisabled}
                    >
                      {t("Client.ObjectivesEvaluation.ValidarCumplimientos")}
                    </Button>
                    <Button variant="danger" onClick={handleRejectSecondScores}>
                      {t("Client.ObjectivesEvaluation.RegresarCumplimientos")}
                    </Button>
                  </Form.Group>
                ) : (
                  <></>
                )}
              </Form>
            </Col>
          </Row>
          <Modal show={showSecondApproval} onHide={handleCloseSecondApproval}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t("Client.ObjectivesEvaluation.RegresarCumplimientos")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="RejectionForm.TextArea">
                  <Form.Label>
                    {t(
                      "Client.ObjectivesEvaluation.PreguntaRegresoCumplimientos"
                    )}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    onChange={handleDeclineComment}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseSecondApproval}>
                {t("Client.ObjectivesEvaluation.Cancelar")}
              </Button>
              <Button variant="danger" onClick={handleBackward}>
                {t("Client.ObjectivesEvaluation.RegresarCumplimientos")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      );
    } else if (status === "finished") {
      return (
        <Container fluid>
          <Row>
            <Col className="objective-evaluation">
              <Form>
                {objectives.map((element) => (
                  <ObjectiveFullfillmentValidationContainer
                    key={element._id}
                    id={element._id}
                    description={element.description}
                    userComment={element.userComment}
                    secondApproverComment={element.secondApproverComment}
                    approverComment={element.approverComment}
                    userScore={element.userScore}
                    approverScore={element.approverScore}
                    secondApproverScore={element.secondApproverScore}
                    evidencePath={element.evidencePath}
                    second={period.secondApproval}
                    handleDownloadEvidence={handleDownloadEvidence}
                    language={state.language}
                  />
                ))}
                <Form.Group className="mb-3">
                  <Button
                    variant="info"
                    onClick={handleForward}
                    disabled={isDisabled}
                  >
                    {t("Client.ObjectivesEvaluation.CerrarEvaluacion")}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => navigate("/dashboard")}
                  >
                    {t("Client.ObjectivesEvaluation.Regresar")}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      );
    } else if (status === "closed") {
      return (
        <Container fluid>
          <Row>
            <Col className="objective-evaluation">
              <Form>
                {objectives.map((element) => (
                  <ObjectiveFullfillmentValidationContainer
                    key={element._id}
                    id={element._id}
                    description={element.description}
                    userComment={element.userComment}
                    secondApproverComment={element.secondApproverComment}
                    approverComment={element.approverComment}
                    userScore={element.userScore}
                    approverScore={element.approverScore}
                    secondApproverScore={element.secondApproverScore}
                    evidencePath={element.evidencePath}
                    second={period.secondApproval}
                    language={state.language}
                  />
                ))}
                <Form.Group className="mb-3">
                  <Button
                    variant="secondary"
                    onClick={() => navigate("/dashboard")}
                  >
                    {t("Client.ObjectivesEvaluation.IrAlTablero")}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default ObjectiveEvaluation;
