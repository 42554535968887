import React, { useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import EvaluationContainer from "./EvaluationContainer";
import ProfileContainer from "./ProfileContainer";
import WeightsContainer from "./WeightsContainer";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./NewReportForm.css";

function NewReportForm() {
  const [name, setName] = useState("");
  const [name_english, setNameEnglish] = useState("");
  const [description, setDescription] = useState("");
  const [description_english, setDescriptionEnglish] = useState("");
  const [visibility, setVisibility] = useState("");
  const [weights, setWeights] = useState([
    { id: uuidv4(), perspective: "boss", weight: 30 },
    { id: uuidv4(), perspective: "auto", weight: 5 },
    { id: uuidv4(), perspective: "peers", weight: 15 },
    { id: uuidv4(), perspective: "subordinates", weight: 20 },
    { id: uuidv4(), perspective: "clients", weight: 30 },
  ]);
  const [profile, setProfile] = useState("");
  const [profiles, setProfiles] = useState([]);

  const [english, setEnglish] = useState(false);

  const [evaluations, setEvaluations] = useState([
    { id: uuidv4(), eval_id: "", weight: 100 },
  ]);
  const [accountEvaluations, setAccountEvaluations] = useState([]);
  const [type, setType] = useState("");
  const [normStatus, setNormStatus] = useState(false);
  const [normLevel, setNormLevel] = useState("");

  const { state } = useContext(AuthContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleNormStatusChange = (e) => {
    setNormStatus(e.target.checked);
  };

  const handleNormLevel = (e) => {
    setNormLevel(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleNameEnglishChange = (e) => {
    setNameEnglish(e.target.value);
  };

  const handleDescriptionEnglishChange = (e) => {
    setDescriptionEnglish(e.target.value);
  };

  const handleVisibilityChange = (e) => {
    setVisibility(e.target.value);
  };

  const handleChangeType = async (e) => {
    try {
      if (e.target.value === "competencies" || e.target.value === "map") {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: e.target.value,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(response.data.evaluations);
      } else if (e.target.value === "performance") {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: e.target.value,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(response.data.evaluations);
      } else if (e.target.value === "hybrid") {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: e.target.value,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(response.data.evaluations);
      } else if (e.target.value === "survey") {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/${state.organization}`,
          data: {
            type: e.target.value,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setAccountEvaluations(response.data.evaluations);
      } else if (e.target.value === "comparison") {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/profiles/organization/succession/${state.organization}`,
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        setProfiles(response.data.profiles);
      }
      setType(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddOption = () => {
    setEvaluations([...evaluations, { id: uuidv4(), eval_id: "", weight: 0 }]);
  };

  const handleChangeInput = (e, id) => {
    if (type === "hybrid") {
      let newEvaluations = evaluations.map((item) =>
        item.id === id ? { ...item, [e.target.name]: e.target.value } : item
      );
      setEvaluations(newEvaluations);
    } else {
      let newEvaluations = [{ eval_id: e.target.value, weight: 100 }];
      setEvaluations(newEvaluations);
    }
  };

  const handleWeightsChange = (e) => {
    let newWeights = weights.map((item) => {
      if (item.perspective === e.target.name) {
        return { ...item, weight: e.target.value };
      } else {
        return item;
      }
    });
    setWeights(newWeights);
  };

  const handleProfileInputChange = (e) => {
    setProfile(e.target.value);
  };

  const handleSubmit = async (e) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports`,
        data: {
          name: name,
          name_english: name_english,
          description: description,
          description_english: description_english,
          visibility: visibility,
          profile: profile,
          evaluations: evaluations,
          type: type,
          normLevel: normLevel,
          normStatus: normStatus,
          weights: weights,
          organization: state.organization,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      navigate("/reports");
    } catch (error) {
      console.log(error);
    }
  };

  let breadcrumbs = [
    {
      title: "Reportes",
      path: "/reports",
    },
  ];

  let content = <></>;

  if (type === "competencies") {
    content = (
      <>
        {/* <Form.Group className="mb-3">
          <Form.Check
            label="Normalizar"
            type="switch"
            onChange={handleNormStatusChange}
          />
        </Form.Group>
        {normStatus === true ? (
          <FloatingLabel label="Normalización" className="mb-3">
            <Form.Select onChange={handleNormLevel}>
              <option value="">Seleccionar nivel</option>
              <option value="99">99%</option>
              <option value="95">95%</option>
              <option value="90">90%</option>
              <option value="85">85%</option>
              <option value="80">80%</option>
              <option value="75">75%</option>
              <option value="70">70%</option>
            </Form.Select>
          </FloatingLabel>
        ) : (
          <></>
        )} */}
        <EvaluationContainer
          evaluations={accountEvaluations}
          handleChangeInput={handleChangeInput}
          type={type}
          language={state.language}
        />
      </>
    );
  } else if (type === "performance") {
    content = (
      <EvaluationContainer
        evaluations={accountEvaluations}
        handleChangeInput={handleChangeInput}
        type={type}
        language={state.language}
      />
    );
  } else if (type === "hybrid") {
    content = (
      <>
        {/* <Form.Group className="mb-3">
          <Form.Check
            label="Normalizar"
            type="switch"
            onChange={handleNormStatusChange}
          />
        </Form.Group>
        {normStatus === true ? (
          <FloatingLabel label="Normalización" className="mb-3">
            <Form.Select onChange={handleNormLevel}>
              <option value="">Seleccionar nivel</option>
              <option value="99">99%</option>
              <option value="95">95%</option>
              <option value="90">90%</option>
              <option value="85">85%</option>
              <option value="80">80%</option>
              <option value="75">75%</option>
              <option value="70">70%</option>
            </Form.Select>
          </FloatingLabel>
        ) : (
          <></>
        )} */}
        {evaluations.map((item) => (
          <EvaluationContainer
            key={item.id}
            id={item.id}
            evaluations={accountEvaluations}
            type={type}
            handleChangeInput={handleChangeInput}
            language={state.language}
          />
        ))}
      </>
    );
  } else if (type === "map") {
    content = (
      <EvaluationContainer
        evaluations={accountEvaluations}
        handleChangeInput={handleChangeInput}
        type={type}
        language={state.language}
      />
    );
  } else if (type === "survey") {
    content = (
      <EvaluationContainer
        evaluations={accountEvaluations}
        handleChangeInput={handleChangeInput}
        type={type}
        language={state.language}
      />
    );
  } else if (type === "comparison") {
    content = (
      <ProfileContainer
        profiles={profiles}
        handleProfileInputChange={handleProfileInputChange}
        language={state.language}
      />
    );
  }

  return (
    <Container fluid>
      <Row>
        <Breadcrumbs data={breadcrumbs} />
      </Row>
      <Row>
        <Col lg={11} className="new-report-header">
          <h2>{t("Reportes.Reporte.NewForm.Titulo")}</h2>
        </Col>
        <Col lg={1} className="new-scale-header mb-2 right">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faList} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={activateEnglish}>
                <FontAwesomeIcon icon={faLanguage} /> English
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Col className="new-report-form">
              <FloatingLabel label="Título" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Titulo"
                  onChange={handleNameChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Title" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    onChange={handleNameEnglishChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <FloatingLabel label="Descripción" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Descripción"
                  onChange={handleDescriptionChange}
                />
              </FloatingLabel>
              {english ? (
                <FloatingLabel label="Description" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    onChange={handleDescriptionEnglishChange}
                  />
                </FloatingLabel>
              ) : (
                <></>
              )}
              <FloatingLabel
                label={state.language === "es" ? "Visibilidad" : "Visibility"}
                className="mb-3"
              >
                <Form.Select onChange={handleVisibilityChange}>
                  <option>
                    {t("Reportes.Reporte.NewForm.SeleccionarNivel")}
                  </option>
                  <option value="admin">
                    {t("Reportes.Reporte.NewForm.Administradores")}
                  </option>
                  <option value="bosses">
                    {t("Reportes.Reporte.NewForm.Jefes")}
                  </option>
                  {/* 
                  <option value="everyone">
                    {t("Reportes.Reporte.NewForm.Todos")}
                  </option> */}
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingSelect"
                label={state.language === "es" ? "Tipo" : "Type"}
                className="mb-3"
              >
                <Form.Select onChange={handleChangeType}>
                  <option>
                    {t("Reportes.Reporte.NewForm.SeleccionarTipo")}
                  </option>
                  <option value="competencies">
                    {t("Reportes.Reporte.NewForm.Talento")}
                  </option>
                  <option value="map">
                    {t("Reportes.Reporte.NewForm.MapaDeTalento")}
                  </option>
                  <option value="performance">
                    {t("Reportes.Reporte.NewForm.Desempeno")}
                  </option>
                  <option value="hybrid">
                    {t("Reportes.Reporte.NewForm.Hibrido")}
                  </option>
                  <option value="survey">
                    {t("Reportes.Reporte.NewForm.Encuesta")}
                  </option>
                  <option value="comparison">
                    {t("Reportes.Reporte.NewForm.Comparativa")}
                  </option>
                  <option value="hobbies">
                    {t("Reportes.Reporte.NewForm.Hobbies")}
                  </option>
                </Form.Select>
              </FloatingLabel>
              {type === "competencies" ? (
                <WeightsContainer handleWeightsChange={handleWeightsChange} />
              ) : type === "hybrid" ? (
                <WeightsContainer handleWeightsChange={handleWeightsChange} />
              ) : (
                <></>
              )}
              {content}
              <Form.Group>
                <Button className="primary" onClick={handleSubmit}>
                  {t("Reportes.Reporte.NewForm.Guardar")}
                </Button>
                <Link to="/reports">
                  <Button className="danger">
                    {t("Reportes.Reporte.NewForm.Cancelar")}
                  </Button>
                </Link>
                {type === "hybrid" ? (
                  <Button className="info space-up" onClick={handleAddOption}>
                    {t("Reportes.Reporte.NewForm.AgregarEvaluacion")}
                  </Button>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default NewReportForm;
