import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./CompetencyFeedback.css";

function CompetencyFeedback({
  id,
  name,
  value,
  questionId,
  questionDescription,
  handleOpenQuestionChange,
}) {
  return (
    <Col className="feedback-container-content">
      <Form.Group className="mb-3">
        <Form.Label>
          {name} - {questionDescription}
        </Form.Label>
        <br></br>
        {/* <Form.Text id="passwordHelpBlock" muted>
          RECOMENDACIÓN: La mejor manera de dar retroalimentación es conforme a
          ejemplos. En lugar de decir "Mejorar su actitúd crítica", explique en
          particular el comportamiento que tuvo la persona y cómo tiene impacto:
          "En las reuniones de avances de resultados, crítica a los
          colaboradores a manera de regaño. Esto provoca que los miembros del
          equipo se enemisten y se crea un ambiente desagradable."
        </Form.Text> */}
        <Form.Control
          as="textarea"
          rows={3}
          onChange={(e) => handleOpenQuestionChange(questionId, id, e)}
          value={value}
        />
      </Form.Group>
    </Col>
  );
}

export default CompetencyFeedback;
