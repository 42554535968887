import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faList,
  faMap,
  faSortAmountUpAlt,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReportPeopleList from "../Components/ReportPeopleList";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import "./ReportPeople.css";

function ReportPeople() {
  const [report, setReport] = useState({});
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let { rid } = useParams();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/participants/${rid}`,
        data: {
          userId: state.userId,
          role: state.role,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      const result = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/${rid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setParticipants(response.data.participants);
      setReport(result.data.report);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const downloadReportCSV = async () => {
    setIsLoading(true);
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/download/csv/${rid}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    const link = document.createElement("a");
    link.download = `${rid}-grades.csv`;

    link.target = "_blank";

    link.href = response.data.url;

    link.click();
    setIsLoading(false);
  };

  let breadcrumbs = [
    {
      title: "Reportes",
      path: "/reports",
    },
  ];

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row className="align-items-center">
          <Col lg={8} className="report-people-header">
            <h2>{report.title}</h2>
          </Col>
          <Col lg={4} className="right report-people-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/reports/ranking/${rid}`}>
                  <FontAwesomeIcon icon={faSortAmountUpAlt} />
                  {t("Reportes.ReporteRanking")}
                </Dropdown.Item>
                {report.type === "hybrid" ? (
                  <Dropdown.Item as={Link} to={`/reports/ninebox/${rid}`}>
                    <FontAwesomeIcon icon={faTh} />{" "}
                    {t("Reportes.ReporteNineBox")}
                  </Dropdown.Item>
                ) : (
                  <></>
                )}
                {report.type === "performance" ? (
                  <Dropdown.Item
                    as={Link}
                    to={`/reports/objectives/map/${rid}`}
                  >
                    <FontAwesomeIcon icon={faMap} />
                    {t("Reportes.MapaEstrategico")}
                  </Dropdown.Item>
                ) : (
                  <></>
                )}
                <Dropdown.Item onClick={() => downloadReportCSV()}>
                  <FontAwesomeIcon icon={faFileExcel} />{" "}
                  {t("Reportes.DescargarReporte")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          {participants.length > 0 ? (
            <ReportPeopleList
              people={participants}
              reportId={rid}
              language={state.language}
            />
          ) : (
            <Col className="empty-reports center">
              <h5>{t("Reportes.SinPersonas")}</h5>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

export default ReportPeople;
