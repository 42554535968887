import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { useWizard } from "react-use-wizard";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import CompetencyFeedback from "./CompetencyFeedback";
import { useTranslation } from "react-i18next";

function QuestionStep({
  id,
  description,
  answers,
  periodName,
  periodName_english,
  handleSubmit,
  show,
  handleClose,
  handleShow,
  handleOpenQuestionChange,
  updateSteps,
}) {
  const {
    stepCount,
    activeStep,
    isFirstStep,
    isLastStep,
    previousStep,
    handleStep,
    nextStep,
  } = useWizard();

  const { t } = useTranslation();

  handleStep(() => {
    updateSteps();
  });

  return (
    <Col className="feedback-container">
      <Col className="feedback-container-header mb-3">
        <h4>{periodName}</h4>
      </Col>
      <Col className="feedback-container-controls mb-3">
        <Row className="justify-content-center align-items-center">
          <Col lg={2}>
            <Button
              className="primary"
              disabled={isFirstStep}
              onClick={() => previousStep()}
            >
              {t("Client.QuestionStep.Anterior")}
            </Button>
          </Col>
          <Col lg={8} className="center">
            <ProgressBar now={((activeStep + 1) / stepCount) * 100} />
          </Col>
          <Col lg={2} className="right">
            {isLastStep ? (
              <Button className="primary" onClick={() => handleShow()}>
                {t("Client.QuestionStep.Enviar")}
              </Button>
            ) : (
              <Button className="primary" onClick={() => nextStep()}>
                {t("Client.QuestionStep.Siguiente")}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      {answers.map((element) => (
        <CompetencyFeedback
          key={element._id}
          id={element._id}
          name={element.name}
          value={element.value}
          questionId={id}
          questionDescription={description}
          periodName={periodName}
          handleSubmit={handleSubmit}
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
          handleOpenQuestionChange={handleOpenQuestionChange}
        />
      ))}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Client.QuestionStep.EnviarEvaluacion")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Client.QuestionStep.UltimaOportunidad")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Client.QuestionStep.Cancelar")}
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {t("Client.QuestionStep.Enviar")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default QuestionStep;
